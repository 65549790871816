import React from "react";
import {Link, graphql } from "gatsby"
import Img from "gatsby-image";
import styled from "styled-components"
import Layout from "../components/layout";
import Header from "../components/Header";

import Footer from "../components/footer.js";
import Text, {Section} from "../components/Theme";
import media from "styled-media-query";
import Carousel from "nuka-carousel";

// import IntroSectionBackground from "../images/background__projects.jpg";
// import Info from "../components/info-icon.js";
import chevronRight from '../images/icons/icon__chevron--white--right.svg';

const SectionGrey = styled.div`
    background-color: var(--off-white);

    &.subscribe-section__greyed {
        margin-bottom: 0 !important;
    }
`;

const ShopContainer = styled(Section)`
    padding-bottom: 0;
    align-items: flex-end;
`;

const ShopText = styled.div`
    grid-column: 4/13;
`;

const LogosCarousel = styled(Carousel)`
    width: 100%;
    grid-column: 1/13;
    grid-row: 3/4;
    align-items: start;
    justify-content: start;
    align-self: start;

    li {
        cursor: default;
    }

    .slider-frame {
        width: 100%;
        padding: 40px 0 !important;
        // overflow: inherit !important;
        mix-blend-mode: multiply;
    }

    .slider-slide {
        padding: 0 20px;

        ${media.lessThan("medium")`
            padding: 0 5px;
        `}
    }

    button {
        background: none;
    }

    .gatsby-image-wrapper {
        margin: auto;
    }

    .shop-slider__slide {
        display: flex;
        align-items: center;
        height: 100%;
        min-height: 50px;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);

        &:hover,
        &:focus {
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
        }
    }

    img {
        margin: auto;
        // padding: 0 30px;
    }
`;

const CarouselFrame = styled(Carousel)`
    width: 100%;

    li {
        cursor: default;
    }

    .slider-frame {
        width: 100%;
    }
`;

const ShopCarousel = styled(Carousel)`
    width: 100%;
    grid-column: 1/13;
    grid-row: 3/4;
    align-items: start;
    justify-content: start;
    align-self: start;
    margin-left: -20px;
    margin-right: -20px;

    li {
        cursor: default;
    }

    .slider-frame {
        width: 100%;
    }

    .slider-slide {
        padding: 0 20px;
    }

    button {
        background: none;
    }

    ${media.greaterThan("large")`
        .slider-control-centerleft {
            left: -40px !important;
        }

        .slider-control-centerright {
            right: -40px !important;
        }
    `}

    ${media.lessThan("large")`
        margin: auto;
    `}
`;


const DailyFlex = styled.div`
    padding: 60px 30px;

    ${media.lessThan("medium")`
        padding: 30px 10px;
    `}

    ${media.lessThan("small")`
        padding: 30px 0;
    `}

    &:nth-of-type(even) {
        margin-top: 60px;

        ${media.lessThan("medium")`
            margin-top: 0;
        `}

        ${media.greaterThan("medium")`
            margin-top: 120px;
        `}
    }
`

const HeroGrid = styled.div`
    .hero-inner {
        display: flex;
        min-height: calc(100vh - 100px);
        z-index: 9998;
        align-items: flex-end;
        color: var(--white);

        ${media.lessThan("large")`
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: calc(100% - 120px);
        `}

        ${media.lessThan("medium")`
            width: calc(100% - 60px);
        `}
    }

    ${media.lessThan("large")`
            display: grid;
            padding-left: 0 !important;
            align-items: center;
            padding-right: 0 !important;
    `}

    .gatsby-image-wrapper {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: -1;
        grid-column: 1/13;
        grid-row: 1;
        width: 100%;
        height: 100vh;
        margin: auto;
    }

    .section-button {
        margin-bottom: 40px;
    }

    .hero-inner__title {
        line-height: 1.15;
    }

    .hero-inner__paragraph {
        margin-bottom: 30px;
        font-size: 1.3rem;
        max-width: 550px;
    }
`;

// const ArticleLink = styled.span`
//     margin-top: 10px;
//     display: block;

//     a {
//         text-decoration: underline;
//     }
// `;

const OverviewContent = styled.div`
    width: 100%;
    padding: 25px 0;
    margin-bottom: 60px;

    ${media.greaterThan("medium")`
        grid-column-start: 8;
    `}

    .daily-header {
        margin-bottom: 30px;
        line-height: 1.15;
    }

    .overview-description {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 30px;
    }

    .meta-btns {
        ${media.lessThan("large")`
            display: block;

            .share-container {
                margin-top: 20px;
            }
        `}
    }
`;

const OverviewImg = styled.div`
    max-width: 100%;
    display: block;
    width: 100%;

    ${media.greaterThan("medium")`
        align-items: center;
        grid-column: 2/8;
        padding-right: 0;
        padding-left: 0;
    `}
`

const NewsletterContainer = styled.div`
    grid-column: 1/13;
    padding: 0;

    h3 {
        margin-top: 0;
    }
`;

const SubscribeBox = styled.div`
    // grid-column: 2/11;
    // padding: 50px 0 0 0;

    input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0;
        width: 100%;
        border: 0px;
        background-color: #f1f1f1;
        background-repeat: no-repeat;
        background-position: 1.5rem center;
        height: 63px;
        font-size: 20px;
        padding: 1rem 2rem;
        grid-column: 1/13;

        &::placeholder {
            opacity: 0.3;
        }

        &[type="submit"] {
            border: 1px solid;
            background-size: 14px;
            background-position: calc(100% - 1rem) center;
            background-repeat: no-repeat;
            background-image: url(${chevronRight});
            background-color: var(--black);
            color: var(--white);
            width: 100%;
            min-width: 100px;
            max-width: 180px;
            text-align: left;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            height: 63px;
            font-size: 20px;
            line-height: 1;
        }
    }
`;

const NewsletterTitle = styled(Text)`
    margin-bottom: 20px;
    color: var(--black);
    font-family: var(--nhp);
    font-weight: 600;
    line-height: 1.2;
    width: 80%;

    a {
        display: block;
        font-size: 11px;
        padding-top: 20px;
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }
`;

const NewsletterParagraph = styled.p`
    color: var(--grey);
    font-family: var(--nhp);
    font-size: 18px;
    line-height: 1.2;
    margin: 20px 0;
`;

const SocialContainer = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const HeroContainer = styled.div`
    background-color: #000;
    width: 100%;
    height: calc(100vh - 100px);

    .slider-frame {
        width: 100%;
    }
`;

const facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=https://www.over-view.com/collaborations/";

const CollaborationsPage = ({ data }) => (

    <Layout parent="collaborations-page">
        <div className="page-collaborations">
            <Header content_color="black"/>

            <HeroContainer>
                <CarouselFrame
                    width="100%"
                    transitionMode="fade"
                    autoplayInterval={3000}
                    pauseOnHover
                    wrapAround
                    slideWidth={1.0}
                    initialSlidewidth={1.0}
                    autoplay
                    renderCenterLeftControls={null}
                    renderCenterRightControls={null}
                >
                {data.datoCmsCollaborationPage.heroSlider.map((block, index) => (
                    <div key={`slide-${index}`}>
                        {/*<Info color={block.color} />*/}
                        {block.overview && (
                        <div className="info-container">
                            <h4 className="body-copy-medium">
                                {block.overview.title}
                            </h4>
                            <div className="body-copy-small">
                                {block.overview.description}
                            </div>
                            <p
                                className={`caption toast-geo ${block.color}`}
                            >
                                {block.overview.geo.latitude.toFixed(
                                    6
                                )}
                                °,{" "}
                                {block.overview.geo.longitude.toFixed(
                                    6
                                )}
                                °
                            </p>
                        </div>
                        )}
                        <HeroGrid>
                            <div className="hero-inner grid-container">
                                <div className="grid-x grid-padding-y">
                                    <div className="cell small-12">
                                        {block.title && (
                                        <Text className="body-copy-medium hero-inner__title">
                                            {block.title}
                                        </Text>
                                        )}
                                        {block.text && (
                                        <div
                                            className="hero-inner__paragraph"
                                            dangerouslySetInnerHTML={{
                                                __html: block.text
                                            }}
                                        />
                                        )}
                                        {block.buttonLink && block.buttonText && (
                                        <Link to={block.buttonLink}>
                                          <button className="section-button section-button__white">
                                              <span>{block.buttonText}</span>
                                              <img
                                                  src={require("../images/icons/icon__chevron--right.svg")}
                                                  alt="daily overview"
                                                  className="image-icon"
                                              />
                                          </button>
                                        </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Img
                                fluid={block.image.fluid}
                                loading="eager"
                                alt={block.title}
                                backgroundColor="#fff"
                                placeholderStyle={{
                                    filter: "blur(10px)"
                                }}
                                fadeIn={false}
                            />
                        </HeroGrid>
                    </div>
                ))}
                </CarouselFrame>
            </HeroContainer>

            <SectionGrey>
                <Section small className="shop-slider">
                    <LogosCarousel
                        width="100%"
                        transitionMode="scroll"
                        autoplayInterval={3000}
                        pauseOnHover
                        wrapAround
                        renderBottomCenterControls={false}
                        slidesToScroll={1}
                        // slidesToShow={4}
                        slidesToShow={typeof window !== 'undefined' && window.outerWidth < 629 ? 1 : 4}
                        slideWidth={1.0}
                        initialSlidewidth={1.0}
                        autoplay
                        renderCenterLeftControls={({ previousSlide }) => (
                            <button onClick={previousSlide}>
                                <img
                                    src={require("../images/icons/icon__chevron--left.svg")}
                                    alt="daily overview"
                                    className="image-icon__large"
                                />
                            </button>
                        )}
                        renderCenterRightControls={({ nextSlide }) => (
                            <button onClick={nextSlide}>
                                <img
                                    src={require("../images/icons/icon__chevron--right.svg")}
                                    alt="daily overview"
                                    className="image-icon__large"
                                />
                            </button>
                        )}
                    >
                        {data.datoCmsCollaborationPage.partnerLogos.map((partner, i) => (
                            <div className="shop-slider__slide" key={`partner-${i}`}>
                                <Link to={partner.link}>
                                {(partner.partnerLogo.mimeType !== 'image/svg+xml') && (
                                    <Img
                                        fluid={partner.partnerLogo.fluid}
                                        alt={partner.name}
                                        durationFadeIn={500}
                                        style={{maxWidth:'120px', margin:'auto'}}
                                    />
                                )}
                                {(partner.partnerLogo.mimeType === 'image/svg+xml') && (
                                    <img
                                        src={partner.partnerLogo.url}
                                        alt={partner.name}
                                        durationFadeIn={500}
                                        style={{maxWidth:'120px', margin:'auto'}}
                                    />
                                )}
                                </Link>
                            </div>
                        ))}
                    </LogosCarousel>
                </Section>
            </SectionGrey>

            <div className="grid-container">

                <div className="grid-x grid-x__custom-padding">
                    {data.allDatoCmsCollaboration.edges.map(({ node: collaboration }, i) => (
                        <DailyFlex key={collaboration.id} id={collaboration.id} className="cell small-12 medium-6">
                            <OverviewImg
                                gutter={collaboration.featuredImage.fluid}
                            >
                                <a
                                    href={`/collaborations/${collaboration.slug}`}
                                    alt="Car Image"
                                    onClick={e => this.handleClick(e, i)}
                                >
                                    <Img
                                        fluid={collaboration.featuredImage.fluid}
                                        alt={collaboration.title}
                                        style={{ backgroundColor: "black" }}
                                        durationFadeIn={500}
                                        placeholderStyle={
                                            {
                                                // "filter": "blur(10px) contrast(1.5) saturate(1.5)",
                                                // "boxShadow": "0 0 100px 200px inset black",
                                            }
                                        }
                                    />
                                </a>
                            </OverviewImg>

                            <OverviewContent>
                                <h2
                                    id="w-node-0e4a4f7ac915-5940d611"
                                    className="subheader daily-header"
                                >
                                    {collaboration.title}
                                </h2>
                                {collaboration.description && (
                                    <p className="overview-description">
                                        {collaboration.description}
                                    </p>
                                )}
                                {/*{collaboration.articleLink && (
                                    <ArticleLink>
                                        <Link
                                            to={`/stories/${collaboration.articleLink.slug}`}
                                        >
                                            Read the full story &rarr;
                                        </Link>
                                    </ArticleLink>
                                )}*/}
                                <div className="meta-btns">
                                    {collaboration.slug && (
                                        <Link to={`/collaborations/${collaboration.slug}`}>
                                          <button
                                            className="section-button"
                                          >
                                              <span>Learn More</span>
                                              <img
                                                  src={require("../images/icons/icon__chevron--white--right.svg")}
                                                  alt="daily overview"
                                                  className="image-icon"
                                              />
                                          </button>
                                        </Link>
                                    )}

                                    <p className="share-container">
                                        <span
                                            href="#"
                                            className="copy__share"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            onClick={() => {
                                                navigator.clipboard.writeText("https://www.over-view.com/collaborations/" + collaboration.slug);
                                            }}
                                        >
                                            <img
                                                src={require("../images/icons/icon__link--grey.svg")}
                                                alt="daily overview: email"
                                                className="image-icon__medium"
                                            />
                                        </span>
                                        <a
                                            href={`mailto:?subject=Check out this post on Overview&body=Check out the ${collaboration.title} post on Overview: https://www.over-view.com/collaborations/${collaboration.slug}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={require("../images/icons/icon__mail--grey.svg")}
                                                alt="daily overview: email"
                                                className="image-icon__medium"
                                            />
                                        </a>
                                        <a
                                            href={
                                                facebookUrl + collaboration.slug
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={require("../images/icons/icon__facebook--grey.svg")}
                                                alt="daily overview: email"
                                                className="image-icon__medium"
                                            />
                                        </a>
                                        <a
                                            href={
                                                "http://twitter.com/share?text=" + collaboration.title + "&url=https://www.over-view.com/collaborations/" + collaboration.slug
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={require("../images/icons/icon__twitter--grey.svg")}
                                                alt="daily overview: email"
                                                className="image-icon__medium"
                                            />
                                        </a>
                                    </p>
                                </div>
                            </OverviewContent>
                        </DailyFlex>
                    ))}
                </div>

                {/*<div class="w-layout-grid partner-subgrid">
                    {data.allDatoCmsCollaboration.edges.map(({ node: collaboration }) => (
                            <div className="partner-block w-inline-block">
                                <Link
                                    to={`/collaborations/${collaboration.slug}`}
                                >

                                    <Img fluid={collaboration.featuredImage.fluid} />
                                    <div className="partnership-text">
                                        <p className="subheader partnership-name">
                                            {collaboration.title}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        )
                    )}
                </div>*/}
            </div>

            {/* TODO: REPLACE WITH COMPONENT VERSION OF NEWSLETTER SIGNUP */}
            {/*<NewsletterSignup />*/}
            <Section small className="subscribe-section subscribe-section__greyed" style={{backgroundColor:'#fcfcfc'}}>
                <NewsletterContainer>
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x">
                            <div className="cell small-12 medium-6">
                                {/* TODO: REPLACE WITH CMS FIELDS */}
                                <SubscribeBox>
                                    <NewsletterTitle sub>
                                        To receive our daily post and the occasional announcement:
                                    </NewsletterTitle>
                                    <form
                                        action="https://www.getrevue.co/profile/dailyoverview/add_subscriber"
                                        method="post"
                                        id="revue-form"
                                        name="revue-form"
                                        target="_blank"
                                        className="subscribe-section__form"
                                    >
                                        <input
                                            className="revue-form-field"
                                            placeholder="Enter your email"
                                            type="email"
                                            name="member[email]"
                                            id="member_email"
                                        />
                                        <input
                                            type="submit"
                                            value="Submit"
                                            name="member[subscribe]"
                                            id="member_submit"
                                            className="revue-form-submit"
                                        />
                                    </form>
                                    {/*<NewsletterParagraph>
                                        Subscribe to receive our best posts once a month
                                    </NewsletterParagraph>*/}
                                </SubscribeBox>
                            </div>
                            <div className="cell small-12 medium-6">
                                <h3>Follow Overview on Social</h3>
                                {/*<NewsletterParagraph>
                                    Join us on the social networks for the latest updates
                                </NewsletterParagraph>*/}
                                <SocialContainer>
                                    <a className="newsletter-social newsletter-social__facebook"
                                       href="https://www.facebook.com/doverview"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       key="SocialContainer1"
                                    >
                                        <img
                                            src={require("../images/icons/icon__facebook.svg")}
                                            width="24"
                                            alt="Daily Overview"
                                            className="grid-right"
                                        />
                                    </a>
                                    <a className="newsletter-social newsletter-social__twitter"
                                       href="https://twitter.com/DOverview"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       key="SocialContainer2"
                                    >
                                        <img
                                            src={require("../images/icons/icon__twitter.svg")}
                                            width="24"
                                            alt="Daily Overview"
                                            className="grid-right"
                                        />
                                    </a>
                                    <a className="newsletter-social newsletter-social__instagram"
                                       href="https://www.instagram.com/dailyoverview/"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       key="SocialContainer3"
                                    >
                                        <img
                                            src={require("../images/icons/icon__instagram.svg")}
                                            width="24"
                                            alt="Daily Overview"
                                            className="grid-right"
                                        />
                                    </a>
                                    <a className="newsletter-social newsletter-social__amazon"
                                       href="https://www.amazon.com/Benjamin-Grant/e/B01HMX7AZU/"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       key="SocialContainer4"
                                    >
                                        <img
                                            src={require("../images/icons/icon__amazon.svg")}
                                            width="24"
                                            alt="Daily Overview"
                                            className="grid-right"
                                        />
                                    </a>
                                </SocialContainer>
                            </div>
                        </div>
                    </div>
                </NewsletterContainer>
            </Section>

            <SectionGrey>
                <ShopContainer small className="shop-slider__intro">
                    <Text
                        sub
                        col="1/4"
                        mobileCol="1/13"
                    >
                        <img
                            src={require("../images/icons/icon__cart.svg")}
                            alt="daily overview"
                            className="image-icon__large"
                        />
                        <span className="section-title">{(data.shopSliderTitle) ? data.shopSliderTitle : 'Shop'}</span>
                    </Text>
                    <ShopText
                        col="4/13"
                        mobileCol="1/13"
                    >
                        <p className="section-lead-paragraph">{(data.shopSliderLeadParagraph) ? data.shopSliderLeadParagraph : 'Through our daily posts, products, and collaborations we aim to inspire the Overview Effect.'}</p>
                    </ShopText>
                </ShopContainer>
                <Section small className="shop-slider">
                    <ShopCarousel
                        width="100%"
                        transitionMode="scroll"
                        autoplayInterval={3000}
                        pauseOnHover
                        wrapAround
                        renderBottomCenterControls={false}
                        slidesToScroll={1}
                        // slidesToShow={this.state.pageWidth < 629 ? 1 : 4}
                        slidesToShow={4}
                        slideWidth={1.0}
                        initialSlidewidth={1.0}
                        autoplay
                        renderCenterLeftControls={({ previousSlide }) => (
                            <button onClick={previousSlide}>
                                <img
                                    src={require("../images/icons/icon__chevron--left.svg")}
                                    alt="daily overview"
                                    className="image-icon__large"
                                />
                            </button>
                        )}
                        renderCenterRightControls={({ nextSlide }) => (
                            <button onClick={nextSlide}>
                                <img
                                    src={require("../images/icons/icon__chevron--right.svg")}
                                    alt="daily overview"
                                    className="image-icon__large"
                                />
                            </button>
                        )}
                    >
                        {data.allDatoCmsPrintshopPage.edges[0].node.shopPageSliderProducts.map((shop_slide, i) => (
                            <Link to={shop_slide.overview.shopLink} key={i}>
                                <div className="shop-slider__slide">
                                    <div className="onebytwo">
                                        <img alt="Daily Overview" src={shop_slide.overview.image.fluid.src} />
                                    </div>
                                    <h4>{shop_slide.overview.title}</h4>
                                </div>
                            </Link>
                        ))}
                    </ShopCarousel>
                </Section>
            </SectionGrey>

            <Footer />
        </div>
    </Layout>
);

export default CollaborationsPage;

export const query = graphql`
    query CollaborationQuery {
      allDatoCmsPrintshopPage {
        edges {
          node {
            shopPageSliderProducts {
              overview {
                id
                title
                slug
                image {
                  fluid(maxWidth: 900, imgixParams: {dpr: 1, w: "900", fit: "clip", auto: "compress"}) {
                    ...GatsbyDatoCmsFluid
                  }
                }
                shopLink
              }
            }
          }
        }
      }
      allDatoCmsCollaboration(sort: {fields: position}) {
        edges {
          node {
            title
            description
            slug
            featuredImage {
              fluid(imgixParams: {h: "400", w: "600", fit: "crop"}) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      datoCmsCollaborationPage {
        heroSlider {
          ... on DatoCmsSlide {
            model {
              apiKey
            }
            color
            title
            buttonText
            buttonLink
            text
            crop
            image {
              fluid(maxWidth: 2000, imgixParams: {q: 85, dpr: 3, w: "2000", fit: "clip", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
            overview {
              title
              description
              slug
              geo {
                latitude
                longitude
              }
            }
          }
        }
        partnerLogos {
          name
          link
          partnerLogo {
            mimeType
            url
            fluid(maxWidth: 2000, imgixParams: {q: 85, dpr: 3, w: "2000", fit: "fit", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        shopSliderTitle
        shopSliderLeadParagraph
      }
    }
`;
